import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RouteWrapper from "./RouteWrapper";

import HomePage from 'views/home';
import CasePage from 'views/case';
import DetailPage from 'views/detail';
import About from 'views/about';

export const AppRouter = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={ <RouteWrapper Component={HomePage} /> } exact/>
            <Route path='/home' element={ <RouteWrapper Component={HomePage} /> } exact/>
            <Route path='/projects' element={ <RouteWrapper Component={CasePage} /> } exact/>
            <Route path='/about' element={ <RouteWrapper Component={About} /> } exact/>
            <Route path='/projects/:id' element={ <RouteWrapper Component={DetailPage} /> } />
        </Routes>
    </BrowserRouter>
);

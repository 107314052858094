import { useEffect } from 'react';
import WOW from 'wowjs';
import { PageLayoutContainer } from "./style";
import Header from "./header";
import Footer from "./footer";

const PageLayout = ({ children }) => {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
      <PageLayoutContainer>
        <Header />
        <div className="page-content">{children}</div>
        <Footer />
      </PageLayoutContainer>
  );
};

export default PageLayout;

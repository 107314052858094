export const colors = {
  purple: '#273366',
  blood: '#F26C6C',
  green: '#247642'
}

export const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  xl: 1200,
  xxl: 1484,
}

import { projects } from 'data';
import ProjectItem from "components/projectItem";
import { Container } from "reactstrap";
import CirclesPattern from '../../assets/img/site/circles-pattern-cases.svg';
import './index.scss';

function CasePage() {
  return (
      <div className='case-page'>
          <div className='project-top-header'>
              <div className='header-overlay'>
                  <Container fluid className='project-header'>
                      <div className='section-title'>
                          <h3 className='title'>Case Studies</h3>
                      </div>

                      <img  className='circles-pattern' src={CirclesPattern} alt=''/>
                  </Container>
              </div>
          </div>
          <div className='case-studies-container'>
            <div className='background-pattern1' />
            <div className='case-studies'>
              <div className='row case-studies-content'>
                  {
                      projects.map((project, index) => {
                          return (
                              <ProjectItem project={ project } key={ index }/>
                          )
                      })
                  }
              </div>
            </div>
          </div>
      </div>
  );
}

export default CasePage;

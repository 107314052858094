import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/img/site/new-logo.png';
import { HeaderContainer } from "./style";
import { Navbar, Nav } from "react-bootstrap";

function Header() {
  const { pathname } = useLocation();
  const [currentMenuKey, setCurrentMenuKey] = useState(pathname.split('/')[1])

  useEffect(() => {
    setCurrentMenuKey(pathname.split('/')[1])
  }, [pathname])

  return (
    <HeaderContainer>
      <Navbar expand='md' variant="dark">
        <Navbar.Brand href='/'>
            <Link to='/'>
                <img className='logo' src={ Logo } alt=''/>
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end">
            <Nav className='ml-auto' activeKey={currentMenuKey} onSelect={eventKey => setCurrentMenuKey(eventKey)}>
                <Nav.Item className='me-md-3'>
                    <Nav.Link as={Link} to='/' eventKey='home' className={`menu-item ${(currentMenuKey === 'home' || currentMenuKey === '') ? 'active' : ''}`}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item className='me-md-3'>
                    <Nav.Link as={Link} to='/projects' eventKey='projects' className={`menu-item ${currentMenuKey === 'projects' ? 'active' : ''}`}>Case Studies</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to='/about' eventKey='about' className={`menu-item ${currentMenuKey === 'technical' ? 'active' : ''}`}>About</Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar.Collapse>
      </Navbar>
    </HeaderContainer>
  );
}

export default Header;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { AppRouter } from 'routers/routers.js';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='App'>
      <AppRouter/>
  </div>
);

reportWebVitals();

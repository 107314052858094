import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { projects } from "data";
import circles from "assets/img/site/circles.png";
import triangle from "assets/img/site/triangle.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import './index.scss';
library.add(faGlobe, faApple, faGooglePlay)

function DetailPage(props) {
  const sliderSettings = {
      dots: true,
      infinite: true,
      autoplay: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      centerPadding: '40px',
      arrows: false,
      initialSlide: 0
  };

  const iconsMap = {
    web: 'fa-solid fa-globe',
    ios: 'fa-brands fa-apple',
    android: 'fa-brands fa-google-play'
  }

  const { id } = useParams();
  const data = projects.filter((p) => p.id === id)[0];

  return (
      <div className='detail-page'>
        <div className='background-pattern1' />
        <div className='page-content'>
          <div className='project-slider'>
              <div className='section-content'>
                  <div className='slider-content'>
                      <Slider { ...sliderSettings }>
                          {
                              data.slides.map((img, index) => {
                                  return (
                                      <div className='slider-item' key={ index }>
                                          <div className='item-content'>
                                              <div className='item-img'>
                                                  <img src={ img } alt=''/>
                                              </div>
                                          </div>
                                      </div>
                                  )
                              })
                          }
                      </Slider>
                  </div>

              </div>
          </div>

          <div className='project-header'>
              <div className='background-header'>
                  <h3 className='background-title'>{ data.name }</h3>
                  <img src={ circles } alt='circles' className='cicle-pattern d-none d-md-block'/>
                  <img alt='triangle' className='triangle d-none d-md-block' src={ triangle }/>
                  <span className='big-circle d-none d-md-block'/>
              </div>
              <div className='title-header'>
                  <h1>{ data.name }</h1>
                  <div className='symbol'/>
              </div>
          </div>

          <div className='m-5 project-detail'>
            <div className='property-desc mb-5'>
              <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
            <div className='row'>
              <div className='col project-property mb-4 col-md-6 col-12'>
                <p className='section-narator'>Technologies Used</p>
                <span className='technologies'>{data.technologies.join(', ')}</span>
              </div>
              <div className='col links col-md-6 col-12'>
                <p className='section-narator'>Available On</p>
                {
                  data.links.map((item, idx) =>
                    <a href={item.link} className='icon' target="_blank"><FontAwesomeIcon icon={iconsMap[item.category]} /></a>
                 )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default DetailPage;

import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import './index.scss';

function ProjectItem(props) {
    const { project } = props;
    return (
        <Col sm={ 6 } md={ 6 } lg={ 3 } className='project-item wow animate__animated animate__bounceIn'>
            <Link to={ '/projects/' + project.id }>
                <figure>
                    <img src={ project.thumbnail } alt=''/>
                    <figcaption>
                        <div className={ 'project-title' }>
                            <span>{ project.name }</span>
                        </div>
                    </figcaption>
                </figure>
            </Link>
        </Col>
    );
}

export default ProjectItem;

import styled from 'styled-components'
import { colors } from 'style'
export const HeaderContainer = styled.div`
  background: ${colors.purple};
  padding: 1.6rem 2rem;
  width: 100%;
  .navbar {
    max-width: 1600px;
    margin: 0 auto;
    .logo {
      width: 120px;
    }
    .navbar-toggler:focus {
      box-shadow: 0 0 0 0.05rem !important;
    }

    .nav-item {
      .menu-item {
        position: relative;
        font-size: 1.2rem;
        color: white !important;
        &.active {
          font-weight: bold;
        }
        &.active::after {
          transform: scale(1);
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 4px;
          left: 5%;
          width: 90%;
          height: 3px;
          background-color: ${colors.blood};
          opacity 1;
          transform: scale(0);
          transform-origin: center;
        }

        &:hover::after,
        &:focus::after {
          transform: scale(1);
        }
      }
    }

    @media (max-width: 768px) {
      .navbar-collapse {

      }
      .nav-item {
        display: flex;
        justify-content: right;
      }
    }
  }
`;

import React, { Component } from 'react';
import { Col } from "reactstrap";
import Step3Image from '../../assets/img/site/step3-img.svg';
import { ForceGraph } from "./forceGraph";
import './index.scss'

function About() {
  return (
      <div className='technical-labs-page'>
          <div className='row about'>
            <Col md={ 7 }>
                <div className='section-title'>
                    <h2 className='title'>Who We Are</h2>
                    <h3 className='title-shadow'>Who We Are</h3>
                </div>

                <p>
                  I am Zheng Wei who has more than 12 years of experience in software development area.
                  I founded the software development team on 2019 since I was dealing large scale projects which were required development team.
                  Byartifact is a 7 members of powerful development team and each developers has more than 10+ years experience in their main skills with latest cutting edge technologies.
                  We are specializing in the creation of custom IT solutions for web, mobile and desktop.
                  We offer visually engaging and user-centric interactive solutions tailored to your business needs.
                </p>
            </Col>
            <Col md={ 5 } className='bg-col'>
                <img className='step-bg d-none d-md-block' src={ Step3Image } alt=''/>
            </Col>
          </div>
          <div className='tech-use'>
            <div className='background-pattern1'/>
            <div className='tech-container'>
              <div className='section-title'>
                  <h2 className='title'>Our Technologies</h2>
                  <h3 className='title-shadow'>Our Technologies</h3>
              </div>

              <div className='d3'>
                <ForceGraph/>
              </div>
            </div>
          </div>
      </div>
  );
}

export default About;

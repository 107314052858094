import * as d3 from "d3";
import styles from "./forceGraph.module.css";
import { skills } from "data"
export function runForceGraph(container) {
  const nodes = []
  const links = []
  skills.forEach((area, aIdx) => {
    nodes.push({
      id: area.area,
      name: '',
      color: '#fff',
      isParent: true
    })
    area.skills.forEach((skill, index) => {
      nodes.push({
        id: aIdx * 1000 + index + 1,
        name: skill.category,
        color: skill.color,
        isParent: skill.skills ? true : false
      })

      // for (let i = index + 1; i < skills.length; i ++) {
      //   links.push({
      //     source: index + 1,
      //     target: i + 1
      //   })
      // }

      links.push({
        source: area.area,
        target: aIdx * 1000 + index + 1
      })

      if (skill.skills) {
        skill.skills.forEach((subSkill, _index) => {
          nodes.push({
            id: aIdx * 1000 + (index + 1) * 100 + _index,
            name: subSkill,
            color: skill.color,
            isParent: false
          })

          links.push({
            source: aIdx * 1000 + index + 1,
            target: aIdx * 1000 + (index + 1) * 100 + _index
          })
        });
      }
    })
  });

  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height;
  const width = containerRect.width;

  const drag = (simulation) => {
    const dragstarted = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (d) => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };

    const dragended = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3.forceLink(links).id((d) => d.id).distance(100)
    )
    .force("charge", d3.forceManyBody().strength(-500))
    .force("x", d3.forceX())
    .force("y", d3.forceY())


  const svg = d3
    .select(container)
    .append("svg")
    .attr("viewBox", [-width / 2, -height / 2, width, height])


  const link = svg
    .append("g")
    .attr("stroke", "#B3B6B7")
    .attr("stroke-opacity", 0.6)
    .selectAll("line")
    .data(links)
    .join("line")
    .attr("stroke-width", (d) => Math.sqrt(d.value));

  const label = svg
    .append("g")
    .attr("class", "labels")
    .selectAll("text")
    .data(nodes)
    .enter()
    .append("text")
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "central")
    .style("fill", d => d.color)
    .style("font-size", d => d.isParent ? '1.8rem' : '1.2rem')
    .style("cursor", 'pointer')
    .text((d) => {
      return d.name;
    })
    .call(drag(simulation));

  simulation.on("tick", () => {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    label
      .attr("x", (d) => {
        return d.x;
      })
      .attr("y", (d) => {
        return d.y;
      });
  });

  return {
    destroy: () => {
      simulation.stop();
    },
    nodes: () => {
      return svg.node();
    },
  };
}

import { Link } from 'react-router-dom';
import Logo from 'assets/img/site/footer-logo.svg';
import { Container, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSkype, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FooterContainer } from "./style";
library.add(faSkype, faWhatsapp, faEnvelope)

function Footer() {
  return (
      <FooterContainer>
          <div className='footer-container'>
              <Row className='footer-content'>
                  <Col md={ 5 } lg={ 5 } className='info'>
                      <div className='logo'>
                          <img src={ Logo } alt=''/>
                      </div>
                      <div className='description'>
                          <p>We are a development team creating better software for people looking to bring an idea
                              to life, scale their business, change the world</p>
                      </div>

                  </Col>
                  <Col md={ 3 } lg={ 3 } className='category'>
                    <div className='pages'>
                      <p className='title'>About Us</p>
                      <Link to='/projects'>+ Case Studies</Link>
                      <Link to='/about'>+ About</Link>
                    </div>
                  </Col>
                  <Col md={ 1 } lg={ 1 } />
                  <Col md={ 3 } lg={ 3 } className='category'>
                      <p className='title'>Contact Us</p>
                      <div className='social'>
                        <a href="skype:live:icream.soft">
                          <span className='icon'><FontAwesomeIcon icon="fab fa-skype" /></span>
                          <span className='id'>live:icream.soft</span>
                        </a>
                        <a href="https://wa.me/+447398740665">
                          <span className='icon'><FontAwesomeIcon icon="fab fa-whatsapp" /></span>
                          <span className='id'>+44 7398740665</span>
                        </a>
                        <a href="mailto:zhengwei@byartifact.com">
                          <span className='icon'><FontAwesomeIcon icon="fa-regular fa-envelope" /></span>
                          <span className='id'>zhengwei@byartifact.com</span>
                        </a>

                      </div>
                  </Col>
              </Row>
          </div>
          <Container fluid>
              <div className={ 'copy-right' }>Copyright &copy; {new Date().getFullYear()} Byartifact. All rights reserved.</div>
          </Container>
      </FooterContainer>
  );
}

export default Footer;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Illustration from 'assets/img/site/illustration.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CirclePattern from 'assets/img/site/circles-pattern.svg';
import CirclePatternLight from 'assets/img/site/circles-pattern-light.svg';
import QuotRight from 'assets/img/site/qout-right.svg';
import QuotLeft from 'assets/img/site/qout-left.svg';
import ProjectItem from 'components/projectItem';
import { Container, Row, Col } from "reactstrap";

import Development from 'assets/img/site/icon-web-development.svg';
import Mobile from 'assets/img/site/icon-mobile-development.svg';
import Blockchain from 'assets/img/site/icon-blockchain-development.svg';
import Design from 'assets/img/site/icon-ui-development.svg';
import Cloud from 'assets/img/site/icon-cloud-development.svg';
import Custom from 'assets/img/site/icon-custom-development.svg';

import Testmonial from 'components/testmonial';
import { projects, clients } from "data";
import './index.scss';


const Icons = {
  development: Development,
  mobile: Mobile,
  blockchain: Blockchain,
  design: Design,
  cloud: Cloud,
  custom: Custom
}


function HomePage() {
  const sliderSettings = {
      dots: true,
      infinite: true,
      autoplay: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      centerPadding: '40px',
      arrows: false,
      initialSlide: 0,

  };

  return (
      <div className='home-page'>
          <div className='top-header'>
              <div class='content'>
                  <Row className='hero'>
                      <img src={ Illustration } alt='circles pattern' className='illustration d-none d-md-block'/>
                      <Col md={ { size: 6, order: 2 } } sm={ { size: 12, order: 1 } } className='info'>
                        <div className='circles-pattern'>
                            <img src={ CirclePattern } alt='circles pattern'/>
                        </div>
                      </Col>
                      <Col md={ { size: 6, order: 1 } } sm={ { size: 12, order: 2 } } className='team-desc'>
                          <h2 className='title wow animate__animated animate__rollIn'>Your success is our utmost priority</h2>
                          <p className='desc wow animate__animated animate__lightSpeedInRight'>We are a development team creating better software for people looking to <span>bring an idea to life, scale their business, change the world</span></p>
                      </Col>
                  </Row>
              </div>
          </div>
          <div className='vertical-lines-bg'>
              <div className='about wow animate__animated animate__fadeInUpBig'>
                  <div className='section-title'>
                      <h3 className='title'>Our Mission</h3>
                      <h2 className='title-shadow'>Our Mission</h2>
                  </div>
                  <div className='about-paragraph'>
                      <section className='introduction'>
                          <p className='desc'>
                          We are a group of dedicated professionals willing to take your business to a whole new level. We create professional software programs as per innovative approaches and the latest trends.
Technology is growing by leaps and bounds and we live in an ever-growing world where digitalization is omnipresent. Information surrounds us and data bombards us, wherever we go. This global pandemic sheds light on the importance of the online building. In line with this challenging situation, having a specialized team by your side is primordial.
We truly believe in the power of technology. Staying up-to-date in this modern world is crucial in achieving milestones. Therefore, we create from scratch websites, applications, and all types of web services to promote your brand so you can accomplish your full potential. Our service offerings help you maximize opportunities in the market and reach your potential customers. We offer full-fledged software solutions so your business can shine and your company can thrive in these disruptive tough times.
We go beyond providing web services and mobile apps. We optimize unparalleled software packages so you can duly brand yourself and stand out amidst a pool of competitors.
                          </p>
                      </section>
                  </div>
              </div>

              <Container className='services wow animate__animated animate__fadeInRightBig'>
                  <div className='section-title'>
                      <h3 className='title'>Our Core Focus</h3>
                      <h2 className='title-shadow'>Our Core Focus</h2>
                  </div>
                  <div className='features-content row'>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.development } alt=''/>
                          </div>
                          <h3 className='title'>Web Development</h3>
                          <p className='description'>
                              We create fully responsive websites and e-commerce for Audience
                          </p>
                      </Col>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.mobile } alt=''/>
                          </div>
                          <h3 className='title '>Mobile Apps</h3>
                          <p className='description'>
                              Native and hybrid mobile apps for iOS, Android and Windows.
                          </p>
                      </Col>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.blockchain } alt=''/>
                          </div>
                          <h3 className='title'>Blockchain</h3>
                          <p className='description'>
                              Infusing blockchain technology into web and mobile applications
                          </p>
                      </Col>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.design } alt=''/>
                          </div>
                          <h3 className='title'>UI/UX Design</h3>
                          <p className='description'>
                              We can help you to strengthen your brand identity with logo's
                          </p>
                      </Col>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.cloud } alt=''/>
                          </div>
                          <h3 className='title'>Cloud</h3>
                          <p className='description'>
                              Building ultimate, flexible enterprise cloud applications for Bussiness
                          </p>
                      </Col>
                      <Col md={ 6 } lg={ 4 } className='item'>
                          <div className='icon-img'>
                              <img className='icon' src={ Icons.custom } alt=''/>
                          </div>
                          <h3 className='title'>Custom Development</h3>
                          <p className='description'>
                              Innovative and scalable software solutions, product engineering etc.
                          </p>
                      </Col>
                  </div>
              </Container>
          </div>
          <div className='pattern-background'>
              <div className='background-pattern' />
              <img className='pattern-circles-top-right d-none d-lg-block' src={ CirclePatternLight } alt='circle pattern'/>
              <div className='case-studies-container'>
                  <div className='section-title'>
                      <h3 className='title'>Our Sample Works</h3>
                      <h1 className='title-shadow'>Our Sample Works</h1>
                  </div>
                  <div className='case-studies'>
                      <Row no-gutter>
                          {
                            projects.slice(0, 4).map((project, index) => {
                                return (
                                    <ProjectItem project={ project } key={ index }/>
                                )
                            })
                          }
                      </Row>
                      <Link to='/projects'>
                          <button className='see-more'>View All</button>
                      </Link>
                  </div>
              </div>
              <img className='pattern-circles-bottom-left d-none d-lg-block' src={ CirclePatternLight } alt='circle pattern'/>
          </div>

          <div className='testimonial'>
              <div className='section-title'>
                  <h3 className='title'>Our Happy Clients</h3>
                  <h1 className='title-shadow'>Our Happy Clients</h1>
              </div>
              <Container className='project-slider'>
                  <img className='quot-left' src={ QuotLeft } alt='quot left'/>
                  <img className='quot-right' src={ QuotRight } alt='quot right'/>
                  <div className='slider-content'>
                      <Slider { ...sliderSettings }>
                          {
                              clients.map((client, index) => <Testmonial client={ client } key={ index }/>)
                          }
                      </Slider>
                  </div>
              </Container>
          </div>
      </div>
  );
}

export default HomePage;

import styled from 'styled-components'
import { colors } from 'style'
import FooterBg from '../../assets/img/site/footer-bg.png';
import FooterBgSmall from '../../assets/img/site/footer-bg-sm.svg';

export const FooterContainer = styled.div`
  width: 100%;
  background-image: url(${FooterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  z-index: 2;
  .footer-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  @media (max-width: 567px) {
    background-image: url(${FooterBgSmall});
  }

  &.footer-overlap {
    min-height: 450px;

    .footer-content {
      padding: 130px 1rem 1rem;
    }
  }

  .footer-content {
    padding: 4rem 1rem 1rem 1rem;
    .info {
      @media (max-width: 767px) {
        text-align: center;
      }
      .logo {
        img {
          width: 65%;
          @media (max-width: 767px) {
            width: 85%;
          }
        }

        @media (max-width: 767px) {
          text-align: center;
        }
      }

    .description {
      margin-top: 16px;
      font-family: Radinka-Light, sans-serif;
      font-size: 0.9rem;
      line-height: 32px;
      color: #fff;
      text-align: left;
      width: 77%;
    }
  }

    .category {
      display: flex;
      flex-direction: column;
      font-family: Radnika-Regular, sans-serif;
      font-size: 18px;
      line-height: 48px;
      color: #FFFFFF;

      @media (max-width: 767px) {
        margin: 2rem 0;
      }

      a {
        height: 40px;
        color: white;
        transition: all .2s ease-in-out;
        &:hover { transform: scale(1.1); }
      }

      .title {
        font-weight: bold;
        color: #F26C6C;
        position: relative;
        font-size: 20px;
        line-height: 48px;


        &::before {
          content: ' ';
          width: 30%;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #F26C6C;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
        }

        &::after {
          content: ' ';
          width: 70%;
          height: 2px;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #545454;
        }
      }

      .pages {
        display: flex;
        flex-direction: column;
      }

      .social {
        display: flex;
        flex-direction: column;

        a {
          display: flex;
          .icon {
            font-size: 1.6rem;
            margin-right: 8px;
          }
          .id {
            font-size: 1rem;
          }

          &:nth-child(3) {
            .icon {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  .copy-right {
    font-family: Radnika-Light, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: normal;
    padding: 2rem;
    color: #FFFFFF;
    text-align: center;
    border-top: 1px solid #545454;

  }
`;

import anecdobio_sample from "data/samples/anecdobio_sample.pdf";

const skills = [
  {
    area: 'dev',
    skills: [
      {
        category: 'Front End',
        color: '#00E1A8',
        skills: [
          'React', 'Angular', 'Vue', 'HTML', 'CSS'
        ]
      },
      {
        category: 'Back End',
        color: '#9900ff',
        skills: [
          'Node.js', '.NET', 'Laravel', 'CodeIgniter', 'SpringBoot'
        ]
      },
      {
        category: 'Mobile',
        color: '#FFA06B',
        skills: [
          'Swift', 'Java', 'Kotlin', 'Objective-C', 'React Native', 'Flutter'
        ]
      },
      {
        category: 'Database',
        color: '#00916C',
        skills: [
          'MySQL', 'PostgreSQL', 'MSSQL', 'MongoDB', 'Oracle', 'Realm'
        ]
      }
    ]
  },
  /*
  {
    area: 'design',
    skills: [
      {
        category: 'Web Design',
        color: '#2ECC71'
      },
      {
        category: 'Mobile Design',
        color: '#F7DC6F'
      },
      {
        category: 'Logo Design',
        color: '#D35400'
      },
      {
        category: 'Game Design',
        color: '#3498DB'
      },
      {
        category: 'Illustration',
        color: '#BB8FCE'
      }
    ]
  },
  {
    area: 'services',
    skills: [
      {
        category: 'Cloud',
        color: '#E67E22',
        skills: [
          'AWS', 'Azure', 'Firebase', 'GCP', 'Alibaba'
        ]
      },
      {
        category: 'Payment',
        color: '#34495E',
        skills: [
          'Stripe', 'Paypal', 'Square', 'Braintree', 'Google Pay', 'Apple Pay'
        ]
      },
      {
        category: 'Help Desk',
        color: '#0E6655',
        skills: [
          'Zendesk', 'Freshdesk', 'PingPilot'
        ]
      },
      {
        category: 'Other',
        color: '#F8C471',
        skills: [
          'Auth0', 'JWT', 'Airtable', 'Swagger', 'Socket.io', 'Jitsi', 'WebRTC', 'Scandit', 'Algolia', 'GraphQL', 'Teraform', 'PDFKit', 'Redis', 'RabitMQ'
        ]
      },
      {
        category: 'Tests',
        color: '#5DADE2',
        skills: [
          'Jest', 'Mocha', 'XCTest', 'Chai', 'JUnit', 'XUnit'
        ]
      }
    ]
  }
  */
]

const projects = [
    {
        id: 'rehabmypatient',
        thumbnail: require('assets/img/projects/rehabmypatient/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/rehabmypatient/slide-1.jpg'),
            require('assets/img/projects/rehabmypatient/slide-2.jpg')
        ],
        name: 'REHAB My Patient',
        links: [
          { category: 'web', link: 'https://www.rehabmypatient.com/'}
        ],
        technologies: ['PHP', 'Angular8', 'WebRTC', 'OpenVidu', 'MySQL'],
        description: 'RehabMyPatient is a clinic software based on UK. It was developed using PHP CodeIgniter framework. We worked on this project for feature updates and also developed multi channel video chat platform. It is based on WebRTC technologies using <a href = "https://openvidu.io/" target="_blank">OpenVidu</a>. It works like Zoom application and totally it is free. That video conference platform was developed during Covid pandemic on 2020 for the patients who wanted to get treatments from home.'
    },
    {
        id: 'dragonfly',
        thumbnail: require('assets/img/projects/dragonfly/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/dragonfly/slide-1.jpg'),
            require('assets/img/projects/dragonfly/slide-2.jpg')
        ],
        name: 'Dragonfly AI',
        links: [
          { category: 'web', link: 'https://www.dragonflyai.co/'}
        ],
        technologies: ['Node.js', 'React.js', 'Micro service', 'Auth0', 'C/C++', 'MongoDB', 'OpenCV'],
        description: 'DragonflyAI is an image analysis application to analyse images and video. For example it can be used to analyse high density images just like satellite pictures. It works for mobile, web and desktop. It also supports APIs and extensions. Main image analysis part was developed using C/C++ based on <a href="https://opencv.org/" target="_blank">OpenCV</a>&nbsp and it is connected to the main Node.js backend program using Node’s N-API. We also developed the main frontend application using React.js'
    },
    {
        id: 'tintworld',
        thumbnail: require('assets/img/projects/tintworld/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/tintworld/slide-1.jpg'),
            require('assets/img/projects/tintworld/slide-2.jpg'),
            require('assets/img/projects/tintworld/slide-3.jpg'),
            require('assets/img/projects/tintworld/slide-4.jpg'),
            require('assets/img/projects/tintworld/slide-5.jpg')
        ],
        name: 'Tint World',
        links: [
          { category: 'web', link: 'https://www.tintworld.com'}
        ],
        technologies: ['Wordpress', 'Vue', 'Stripe', 'Google Place API', 'PHP', 'MySQL'],
        description: 'TintWorld is the world wide well-known auto styling service. The main website was based on Wordpress. We developed their main menu that displays services, products, deals, etc using Vue3 and successfully integrated to the main Wordpress application. We also customized their payment gateway as a wordpress plugin.'
    },
    {
        id: 'anecdobio',
        thumbnail: require('assets/img/projects/anecdobio/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/anecdobio/slide-1.jpg'),
            require('assets/img/projects/anecdobio/slide-2.jpg'),
            require('assets/img/projects/anecdobio/slide-3.jpg'),
            require('assets/img/projects/anecdobio/slide-4.jpg')
        ],
        name: 'Anecdobio',
        links: [
          { category: 'web', link: 'https://test.anecdobio.com/'}
        ],
        technologies: ['Node.js', 'React.js', 'AWS', 'Stripe', 'Lambda', 'S3', 'MySQL'],
        description: `Anecdobio is a service to create books from stories. Please review the sample book which is generated by Anecdobio when we finished the first beta testing. Here is the <a href=${anecdobio_sample} target="_blank" rel="noreferrer">link</a>`
    },
    {
        id: 'youdollyou',
        thumbnail: require('assets/img/projects/youdollyou/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/youdollyou/slide-2.jpg')
        ],
        name: 'Youdollyou',
        links: [
          { category: 'web', link: 'https://youdollyou.com/'}
        ],
        technologies: ['PHP', 'Wordpress', 'MySQL', 'Paypal', 'Stripe', 'Hitappointment'],
        description: 'Youdollyou is an eyelash service from US. The main website was developed using wordpress and the main appointment service was developed using <a href="https://www.hitappoint.com/" target="_blank">Hitappointment</a>.&nbsp We worked on updating features of appointment service.'
    },
    {
        id: 'nsf',
        thumbnail: require('assets/img/projects/nsf/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/nsf/slide-1.jpg'),
            require('assets/img/projects/nsf/slide-2.jpg'),
            require('assets/img/projects/nsf/slide-3.jpg')
        ],
        name: 'NSF Science Zone',
        links: [
          { category: 'ios', link: 'https://apps.apple.com/us/app/nsf-science-zone/id439928181'},
          { category: 'android', link: 'https://play.google.com/store/apps/details?id=com.nsf.nsfsciencezone&hl=en'}
        ],
        technologies: ['Swift', 'Java'],
        description: 'NSF Science Zone is a mobile application for students and teachers. It provides hundreds of exciting videos and high-resolution photos from a dozen areas of science. We used some nice technologies for video streaming, caching and performance update.'
    },
    {
        id: 'giantsquare',
        thumbnail: require('assets/img/projects/giantsquare/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/giantsquare/slide-1.jpg'),
            require('assets/img/projects/giantsquare/slide-2.jpg')
        ],
        name: 'Giant Square',
        links: [
          { category: 'ios', link: 'https://apps.apple.com/us/app/giant-square-grids-collages/id638402155'},
          { category: 'android', link: 'https://play.google.com/store/apps/details?id=com.giantsquare.bluepro&hl=en_US&gl=US'}
        ],
        technologies: ['Objective-C', 'Java'],
        description: 'Giant Square is the FIRST app to let you create big pictures, panorama pictures and banners on Instagram! It also let you avoid cropping your pictures on Instagram with our Square editor that let you avoid cropping panorama pictures when uploading them to Instagram. We used <a href="https://github.com/BradLarson/GPUImage" target="_blank">GPU Image</a>&nbsp for image processing.'
    },
    {
        id: 'swhoon',
        thumbnail: require('assets/img/projects/swhoon/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/swhoon/slide-1.jpg'),
            require('assets/img/projects/swhoon/slide-2.jpg')
        ],
        name: 'Swhoon',
        links: [
          { category: 'ios', link: 'https://apps.apple.com/us/app/swhoon/id1598988088'}
        ],
        technologies: ['Swift', 'RxSwift', 'SwiftUI'],
        description: `Swhoon is an iOS mobile application for the track and autocross for car racing.
                      <br><br><i>Features</i><br>
                      <ul>
                      <li>Recorded lap data is automatically ssent to the cloud for other racers to compare</li>
                      <li>Follow other racers, Like and Comment on racing laps</li>
                      <li>Leaderboard</li>
                      </ul>`
    },
    {
        id: 'woobody',
        thumbnail: require('assets/img/projects/woobody/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/woobody/slide-1.jpg'),
            require('assets/img/projects/woobody/slide-2.jpg')
        ],
        name: 'Woobody',
        links: [
          { category: 'ios', link: 'https://apps.apple.com/bg/app/woobody/id1645335158'},
          { category: 'android', link: 'https://play.google.com/store/apps/details?id=com.woobody.app'},
          { category: 'web', link: 'https://woobody.com'}
        ],
        technologies: ['Swift', 'Kotlin', 'PHP', 'WebRTC', 'Firebase', 'MySQL'],
        description: `Woobody is iOS/Android mobile application for live training studio with real-time classes with real trainers for yoga, meditation, workout and more.
                      <br><br><strong>Features</strong><br>
                      <ul>
                      <li>Online Studio - Trainers create a list of different classes</li>
                      <li>Find a Class - Trainers can search all the available classes</li>
                      <li>Quick Preview - Each class has all the necessary details</li>
                      <li>Training Process - Real-time training through a mobile app</li>
                      </ul>`
    },
    {
        id: 'vylo',
        thumbnail: require('assets/img/projects/vylo/thumbnail.jpg'),
        slides: [
            require('assets/img/projects/vylo/slide-1.jpg'),
            require('assets/img/projects/vylo/slide-2.jpg')
        ],
        name: 'Vylo',
        links: [
          { category: 'ios', link: 'https://apps.apple.com/us/app/vylo/id1627569855'},
          { category: 'android', link: 'https://play.google.com/store/apps/details?id=com.vylo.androidapplication&pli=1'},
          { category: 'web', link: 'https://vylo.com'}
        ],
        technologies: ['Swift', 'Kotlin', 'Vue', 'Django', 'NuxtJS'],
        description: `Vylo is the first social media news app for video and audio responses to news that matters to you.
                      <br><br><strong>Features</strong><br>
                      <ul>
                      <li>Watch video/audio responses to news articles that interest you</li>
                      <li>Browse original news and perspectives from friends & creators</li>
                      <li>Curate your newsstand with the topics you care about</li>
                      </ul>`
    }
];

const clients = [
  {
    quote: '“Byrtifact has given us a great product delivered on a timely manner. I have to say that this is one of the best teams I have met thus far. They are very fast developers and extremely easy to work with. We would go back to them for further work and highly recommend them to other potential clients ”',
    picture: require('assets/img/clients/natural-releaf.png'),
    name: '',
    job: 'The Natural Releaf'
  },
  {
    quote: '“Byartifact are a pleasure to work with. Great communication, quality, output, and quick turnaround. Thanks very much!”',
    picture: require('assets/img/clients/sean_christianson.png'),
    name: 'Sean Christianson',
    job: 'CTO at TintWorld'
  },
  {
    quote: '“Zheng really knows his stuff! We have thrown some challenging tasks at him that require very critical and creative thinking, and he is been able to find solutions. Not everyone can do that! We are happy to work with him and believe others would be as well. Communication is also super easy.”',
    picture: require('assets/img/clients/wolf.png'),
    name: 'Wolfgang Schroen',
    job: 'CEO at Anecdobio'
  },
  {
    quote: '“Zheng and his team are not only highly talented, but are completely dedicated to delivering a top product in every way. I’d recommend Byartifact to anyone seeking a prime development team with excellent communication.”',
    picture: require('assets/img/clients/tyler.jpg'),
    name: 'Tyler Reynolds',
    job: 'CEO at Vylo'
  }
];

export {
  skills,
  projects,
  clients
}

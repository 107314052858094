import { Route } from 'react-router-dom';
import PageLayout from 'layouts';

const RouteWrapper = ({Component}) => {
  return (
    <PageLayout>
      <Component />
    </PageLayout>
  )
}

export default RouteWrapper;

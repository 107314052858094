import './index.scss';

function Testmonial(props) {
  const {client} = props;
  return (
      <div className='wrapper'>
          <div className={'col-12'}>
              <div className='quote'>
                  <span>{client.quote}</span>
              </div>
              <div className='client-info'>
                <img className='picture' src={client.picture}/>
                <span className='name'>{client.name} </span>
                <span className='job'>{client.job} </span>
              </div>
          </div>
      </div>
  );
}

export default Testmonial;
